import React, { useEffect } from "react";
import styled from "styled-components";
import theme from "../../../assets/theme";
import { HeadWrap } from "./Styles";
import { navigate } from "@reach/router";
import { Helmet } from "react-helmet";
import COLORS from "../../../assets/Colors";

function Blog(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const actions = [
    {
      icon: (
        <Icon
          src={require("../../../assets/images/JustActMailers_MSME-01.png")}
        />
      ),
      heading:
        "ADR PROCESSES WITHIN THE MICRO, SMALL AND MEDIUM ENTERPRISES DEVELOPMENT ACT, 2006",
      newIcon: (
        <NewIcon src={require("../../../assets/images/new-gif-icon.gif")} />
      ),
      body: `The Micro, Small &amp; Medium Enterprises (MSMEs) have a pivotal role to play in the creation
      of employment opportunities within developing areas of the country...`,
      path: "/blog/enterprises-development",
    },
    {
      icon: (
        <Icon
          src={require("../../../assets/images/JustActMailers_SME-01.png")}
        />
      ),
      heading: "ROLE OF MEDIATION IN SME DISPUTES",
      body: `Small and Medium Sized Enterprises (SME) are categorized as businesses whose investment,
      revenue and employee base are restricted to predetermined limits...`,
      path: "/blog/role-of-mediation",
    },
    {
      icon: (
        <Icon
          src={require("../../../assets/images/JustActMailers_1.1 Arbitrators-01.png")}
        />
      ),
      heading:
        "What Kind of Training is Required to Build a Country of Arbitrators?",
      body: `Arbitration in India has evolved over the preceding centuries to become one of the most well-
      regarded modes of dispute resolution, especially in the commercial world...`,
      path: "/blog/country-of-arbitrators",
    },
    {
      icon: (
        <Icon
          src={require("../../../assets/images/JustActMailers_country.png")}
        />
      ),
      heading:
        "What Kind of Training is Required to Build a Country of Mediators?",
      body: `Mediation is gaining ground as a mainstream dispute resolution process for settling
      commercial and financial disputes across the world...`,
      path: "/blog/country-of-mediators",
    },
    {
      icon: (
        <Icon
          src={require("../../../assets/images/JustActMailers_10-13.jpeg")}
        />
      ),
      heading:
        "Breaking Impasses in Settlement Conferences: Five Techniques for Resolution",
      body: `An impasse signifies a stalemate or deadlock in discussions when parties are unwilling to
      budge from their respective positions for stated reasons...`,
      path: "/blog/technique-for-resolution",
    },
    {
      icon: (
        <Icon
          src={require("../../../assets/images/JustActMailers_10-11.jpg")}
        />
      ),
      heading: "SUBJECT MATTER EXPERTS IN ARBITRATIONS",
      body: `The increase in the volume of global trade has led to a corresponding rise in commercial and
      financial disputes being referred to various mechanisms of dispute resolution...`,
      path: "/blog/experts-in-arbitration",
    },
    {
      icon: (
        <Icon
          src={require("../../../assets/images/Limitatin In Arbitration.jpg")}
        />
      ),
      heading: "LIMITATION IN ARBITRATION Part II",
      body: `This article will further discuss the shortcomings of the application of limitation in arbitration
      proceedings through the following entry points...`,
      path: "/blog/limitation-in-arbitration-two",
    },
    {
      icon: (
        <Icon
          src={require("../../../assets/images/JustActMailers_10-09.jpg")}
        />
      ),
      heading: "NON-ARBITRABLE CASES",
      body: `The case is foundational to the development of the parameters of non-arbitrability of disputes and the Supreme 
      Court laid down general rules in this regard while adjudicating on whether an action for the enforcement of a mortgage is arbitrable...`,
      path: "/blog/non-arbitrable-cases-two",
    },
    {
      icon: (
        <Icon
          src={require("../../../assets/images/JustActMailers_6_V2-02.jpg")}
        />
      ),
      heading: "Role of Arbitration in Criminal Cases",
      body: `However, this position on fraud as a criminal offence and the arbitrability of such disputes saw a shift 
      in the emerging jurisprudence of the later years, thus underlining the role of arbitration in criminal cases...`,
      path: "/blog/role-of-arbitration-in-criminal-cases-part-2",
    },
    {
      icon: (
        <Icon
          src={require("../../../assets/images/JustActMailers_10-01.jpg")}
        />
      ),
      heading: "Mediation Act, 2023",
      body: `In the labyrinth of legal battles, where adversarial swords clash, there exists a quieter path—a path illuminated by
      dialogue, empathy, and mutual understanding.`,
      path: "/blog/mediation-act-2023",
    },
    {
      icon: (
        <Icon
          src={require("../../../assets/images/JustActMailers_6_V2-01.jpg")}
        />
      ),
      heading: "Role of Arbitration in Criminal Cases",
      body: `Arbitral tribunals generally possess the jurisdiction to adjudicate upon civil and commercial
      disputes subject to the dispute being covered under the arbitration...`,
      path: "/blog/role-of-arbitration-in-criminal-cases",
    },
    {
      icon: (
        <Icon
          src={require("../../../assets/images/JustActMailers_9_V1-02.jpg")}
        />
      ),
      heading: "Enforcement of Foreign Arbitral Awards",
      body: `Prior to the enactment of The Arbitration and Conciliation Act, 1996 which was modelled on the UNCITRAL Model Law, the Arbitration Act, 1940 was enacted to consolidate and amend the law relating to arbitration...`,
      path: "/blog/enforcement-of-foreign-arbitral-awards",
    },
    {
      icon: (
        <Icon
          src={require("../../../assets/images/JustActMailers_7_V2-01.jpg")}
        />
      ),
      heading: "LIMITATION IN ARBITRATION",
      body: `The Limitation Act, 1963 is a legislation that bars legal remedy
        to those who have not exercised their right to act on a
        particular issue within the bounds...`,
      path: "/blog/limitation-in-arbitration",
    },
    {
      icon: (
        <Icon
          src={require("../../../assets/images/Creative for JA Blog - 27.06.23.jpg")}
        />
      ),
      heading: "TIME & COST BENEFITS OF ARBITRATION & MEDIATION",
      body: `Although the traditional court system is associated primarily
      with the dispensation of justice, methods of Alternative Dispute
      Resolution...`,
      path: "/blog/time-cost-benefits-of-arbitration-mediation",
    },
    {
      icon: (
        <Icon
          src={require("../../../assets/images/JustActMailers_9_V1-01.jpg")}
        />
      ),
      heading: "NON-ARBITRABLE CASES",
      body: `Alternative Dispute Resolution as a means of settling disputes
      out of court is gaining precedence across the world. Most
      disputes that arise may be resolved...`,
      path: "/blog/non-arbitrable-cases",
    },
    {
      icon: (
        <Icon
          src={require("../../../assets/images/JustAct Blog Creative 20.03.23.jpg")}
        />
      ),
      heading: `Perkins Eastman Judgement: Impact on the Appointment of a Sole
      Arbitrator`,
      body: `The judgement examined whether a case was made out for the
      exercise of power by the Court to make an....`,
      path: "/blog/perkins-eastman-judgement",
    },
    {
      icon: (
        <Icon
          src={require("../../../assets/images/JustActMailers_7_V1-01.jpg")}
        />
      ),
      heading: "Enforcement of Foreign Arbitral Awards",
      newIcon: "",
      body: `Effective and speedy dispute resolution mechanisms for domestic
      and global investors are essential in order to facilitate....`,
      path: "/blog/enforcement-of-foreign-arbital-awards",
    },
    {
      icon: (
        <Icon
          src={require("../../../assets/images/Mediation picture (1).jpg")}
        />
      ),
      heading: "Culture of mediation: why has it not picked up in India?",
      body: `Alternate Dispute Resolution (ADR) refers to modes by which
      disputes may be resolved without taking recourse to the court
      system...`,
      path: "/blog/culture-of-mediation-why-has-it-not-picked-up-in-india",
    },
    {
      icon: (
        <Icon src={require("../../../assets/images/Trackling_Thumbnail.png")} />
      ),
      heading: "Tackling pending cases in Indian Courts",
      body: `Every year, a minimum of five crore cases are filed across
      various courts of India. Even as some of these cases stay
      pending...`,
      path: "/blog/tackling-pending-cases-in-indian-courts",
    },
    {
      icon: <Icon src={require("../../../assets/images/JA Thumbnail.png")} />,
      heading: "Advantages of ODR",
      body: `The world now bears witness to an unprecedented situation, with
        the pandemic and its conditions rendering aspects far from a
        sense of normal...`,
      path: "/blog/advantages-of-ODR",
    },
    {
      icon: (
        <Icon src={require("../../../assets/images/Thumbnail_S1129A.png")} />
      ),
      heading:
        "Section 11 & 29A: Impeding or expediting the Arbitration process?",
      body: `Through the present health catastrophe that the world knows as
        COVID-19, arbitration has been shown to adapt better...`,
      path: "/blog/impeding-or-expediting-the-arbitration-process",
    },
    {
      icon: (
        <Icon src={require("../../../assets/images/Thumbnail_Nitika.png")} />
      ),
      heading:
        "Evaluating ADR for resolving women-related disputes (Part 3 of 3)",
      body: `Arbitration, mediation, or consultation exemplify Alternative
      Conflict Resolution (ADR)...`,
      path: "/blog/evaluating-ADR-for-resolving-women-related-disputes",
    },
    {
      icon: (
        <Icon src={require("../../../assets/images/Thumbnail_Vismita.png")} />
      ),
      heading:
        "ADR and its applications to women-related disputes (Part 2 of 3)",
      body: `Violence against women is a matter of grave concern. The
      immediate and long-term physical, sexual, and mental
      consequences...`,
      path: "/blog/ADR-and-its-applications-to-women-related-disputes",
    },
    {
      icon: <Icon src={require("../../../assets/images/Thumbnail4.png")} />,
      heading: " Resolving women-related disputes (Part 1 of 3)",
      body: `Women are victims of different social evils. They are subjected
      to problems at their homes, at the workplace, in society, etc.
      One of the significant...`,
      path: "/blog/resolving-women-related-disputes",
    },
    {
      icon: <Icon src={require("../../../assets/images/Thumbnail3.png")} />,
      heading: "The scope and relevance of ODR",
      body: `These are the wise words of the man who is credited with
      inventing the world wide web, and a rather telling observation
      by him. We can hardly imagine a world...`,
      path: "/blog/the-scope-and-relevance-of-ODR",
    },
    {
      icon: <Icon src={require("../../../assets/images/Thumbnail2.png")} />,
      heading: "Understanding Arbitration and The Arbitration Act",
      body: `Dispute Resolution uses the mechanisms of Negotiation and
        Mediation in order to let parties arrive at a consensual
        resolution...`,
      path: "/blog/understanding-arbitration-and-the-arbitration-act",
    },
    {
      icon: <Icon src={require("../../../assets/images/Thumbnail.png")} />,
      heading: "Online Dispute Resolution: Opportunities and challenges",
      body: `Embracing an idea means embracing its methods. Embracing ODR
      needs to be done rapidly but not at the cost of sacrificing the
      principles...`,
      path: "/blog/online-dispute-resolution-opportunities-and-challenges",
    },
    {
      icon: <Icon src={require("../../../assets/images/Thumbnail.png")} />,
      heading: "The basics of Online Dispute Resolution",
      body: ` Before and through the pandemic, the world changed radically.
        One would observe that there is a massive concentration of
        wealth...`,
      path: "/blog/the-basics-of-online-dispute-resolution",
    },
  ];

  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <title>Blog - Dispute Resolution Online - JustAct</title>
        <meta
          name="description"
          content="JustAct blogs related to all kinds of technology-assisted dispute resolution services including negotiation, mediation and arbitration."
        />
        <meta
          name="keywords"
          content="Alternative Dispute Resolution, Dispute Resolution Online, Dispute Blogs, Online Arbitration, Online Mediation, Online Dispute Resolution, JustAct"
        />
      </Helmet>
      <HeadWrap
        style={{
          paddingTop: "40px",
        }}
      >
        Blogs
      </HeadWrap>
      <CardContainer>
        {actions &&
          actions.map((blog) => (
            <Card>
              {blog.icon}
              <CardHeading>
                {blog.heading}
                {blog?.newIcon && (
                  <NewIcon
                    src={require("../../../assets/images/new-gif-icon.gif")}
                  />
                )}
              </CardHeading>
              <CardBody>{blog.body}</CardBody>
              <BtnStyle onClick={() => navigate(blog.path)}>Read More</BtnStyle>
            </Card>
          ))}
      </CardContainer>
    </>
  );
}

export default Blog;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 40px;
  @media (max-width: 960px) {
    margin: 40px 0px;
  }
`;
const CardBody = styled.div`
  font-size: 16px;
  font-family: ${theme.fonts.primaryFontRegular};
  margin: 0 20px 15px;
  text-align: left;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

export const NewIcon = styled.img`
  width: 33px;
  height: 30px;
  vertical-align: middle;
`;

export const Icon = styled.img`
  width: 100%;
  height: 150;
  object-fit: contain;
  margin-right: 10px;
  color: ${COLORS.COLOR_DARK};
`;

const CardHeading = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.COLOR_DARK};
  font-size: 18px;
  font-weight: 600;
  margin: 20px 20px 10px 20px;
  text-align: left;
`;

const Card = styled.div`
  flex-direction: column;
  justify-content: space-between;
  width: 400px;
  margin: 20px;
  border: 1px solid ${COLORS.BORDER_GREY};
  background-color: #ffffff;
  border-radius: 2px;
  @media (max-width: 960px) {
    margin: 20px 0px;
    width: 80%;
  }
`;

const BtnStyle = styled.section`
  color: #00838c;
  display: flex;
  font-size: 16px;
  flex-direction: row;
  cursor: pointer;
  text-align: left;
  float: left;
  margin: 0 20px 20px 20px;
  font-family: ${theme.fonts.primaryFontBold};
`;
